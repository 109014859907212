<!-- this.$apollo.loading -->
<template>
    <div>
        <div>
            <b-card>
                <b-card-body>
                    <b-row>
                        <b-col cols="6">
                            <h1>Hackathons</h1>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <el-tooltip content="Add" placement="top">
                                <router-link
                                    :to="{
                                        path:'/hackathon/new/timeline'
                                    }"
                                >
                                    <base-button
                                        type="primary"
                                        round
                                        icon
                                        size="sm"
                                    >
                                        <span class="btn-inner"
                                            ><i class="fa fa-plus"></i
                                        ></span>
                                    </base-button>
                                </router-link>
                            </el-tooltip>
                        </b-col>
                    </b-row>
                    <div v-if="$apollo.loading && table_data.length == 0">
                        <b-row
                            class="mt-2 d-flex align-items-center justify-content-center"
                        >
                            <b-col
                                class="d-flex align-items-center justify-content-center"
                            >
                                <Transition>
                                    <h1>
                                        <i
                                            class="fas fa-spinner fa-spin ml-1"
                                        ></i>
                                    </h1>
                                </Transition>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row v-else>
                        <b-col>
                            <el-table
                                class="table-responsive align-items-center table-flush no-lines"
                                header-row-class-name="thead-light"
                                row-class-name="table-rows"
                                :data="table_data"
                                empty-text="No hackathons yet, use the '+' button to create your first hackathon."
                            >
                                <el-table-column
                                    label="Name"
                                    min-width="240px"
                                    sortable
                                >
                                    <template v-slot="{ row }">
                                        <p class="mb-0">
                                            <router-link
                                                :to="{
                                                    path: `/hackathon/${encodeURIComponent(
                                                        row.id
                                                    )}/detail`,
                                                }"
                                                >{{ row.name }}</router-link
                                            >
                                        </p>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    label="Organization"
                                    min-width="240"
                                    sortable
                                >
                                    <template v-slot="{ row }">
                                        <p class="mb-0">
                                            {{ row.organization }}
                                        </p>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    label="Status"
                                    min-width="130"
                                    sortable
                                >
                                    <template v-slot="{ row }">
                                        <p class="mb-0">
                                            {{ row.status }}
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="Starting"
                                    min-width="200px"
                                    sortable
                                >
                                    <template v-slot="{ row }">
                                        <p class="mb-0">
                                            {{
                                                dateTimeFormat(
                                                    row.startDatetime
                                                )
                                            }}
                                        </p>
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    label="Ending"
                                    min-width="240px"
                                    sortable
                                >
                                    <template v-slot="{ row }">
                                        <p class="mb-0">
                                            {{
                                                dateTimeFormat(row.endDatetime)
                                            }}
                                        </p>
                                    </template>
                                </el-table-column>

                                <el-table-column min-width="80" align="center">
                                    <div
                                        slot-scope="{ row }"
                                        class="table-actions"
                                    >
                                        <el-tooltip
                                            content="Edit Hackathon"
                                            placement="top"
                                        >
                                            <b-button
                                                :pill="true"
                                                class="btn-success btn-details"
                                                @click="
                                                    $router.push({
                                                        path: `/hackathon/${encodeURIComponent(
                                                            row.id
                                                        )}/timeline`,
                                                    })
                                                "
                                                size="sm"
                                            >
                                                <i class="fas fa-cog"></i>
                                            </b-button>
                                        </el-tooltip>
                                        <!-- <el-tooltip
                                                        content="View Hackathon"
                                                        placement="top"
                                                    >
                                                        <b-button
                                                            :pill="true"
                                                            class="btn-info btn-details"
                                                            @click="
                                                                $router.push({
                                                                    path: `/hackathon/${encodeURIComponent(
                                                                        row.id
                                                                    )}/detail`,
                                                                })
                                                            "
                                                            size="sm"
                                                        >
                                                            <i
                                                                class="fas fa-eye"
                                                            ></i>
                                                        </b-button>
                                                    </el-tooltip> -->
                                    </div>
                                </el-table-column>
                            </el-table>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </div>
    </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { GET_HACKATHON_FROM_USER } from "@/graphql/queries";
import { Table, TableColumn, Tooltip } from "element-ui";

export default {
    name: "ListHackathons",

    components: {
        BaseInput,
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    apollo: {
        allHackathonHackathon: {
            query: GET_HACKATHON_FROM_USER,
            result(data) {
                this.getHackathonFromUser(data);
                this.table_is_loading = false;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    creator: this.$store.getters.getUserIdB64,
                };
            },
        },
    },

    data() {
        return {
            int_user: "",
            table_data: [],
            table_is_loading: false,
            fields: [
                { key: "name", sortable: true, label: "Name" },
                // { key: "description", sortable: true, label: "Short Description" },
                // {
                //   key: "allowedParticipants.friendlyName",
                //   sortable: true,
                //   label: "Participants",
                // },
                // { key: "maxTeamSize", sortable: true, label: "Team Size" },
                {
                    key: "organization",
                    sortable: true,
                    label: "Organization",
                },
                {
                    key: "status",
                    sortable: true,
                    label: "Status",
                },
                {
                    key: "startDatetime",
                    sortable: true,
                    label: "Start",
                    formatter: "dateTimeFormat",
                    width: "20px",
                },
                {
                    key: "endDatetime",
                    sortable: true,
                    label: "End",
                    formatter: "dateTimeFormat",
                },

                { key: "details", label: "" },
            ],
        };
    },

    methods: {
        setUser() {
            this.int_user = utils.deepcopy(this.$store.getters.getUser);
        },

        createHackathonPage() {
            this.$router.push("create");
        },

        dateTimeFormat(data) {
            let date = data.substring(0, 10);
            let time = data.substring(11, 16);
            return date + " " + time;
        },
        //Apollo Methods
        getHackathonFromUser(data) {
            this.table_data = [];
            data.data.allHackathonHackathon.edges.forEach((i) => {
                let data_item = {
                    id: i.node.id,
                    name: i.node.name,
                    startDatetime: i.node.startDatetime,
                    endDatetime: i.node.endDatetime,
                    status: i.node.statusDisplay,
                };
                if (i.node.organization) {
                    data_item.organization = i.node.organization.name;
                }

                this.table_data.push(data_item);
            });
        },
    },

    created() {
        this.table_is_loading = true;
        global_event_emitter.$on("hackathon_sidebar_reload_required", () => {
            setTimeout(() => {
                this.$apollo.queries.allHackathonHackathon.refetch();
            }, 500);
        });
    },
    mounted() {
        this.table_is_loading = true;
        this.$apollo.queries.allHackathonHackathon.refetch();
    },
    beforeDestroy() {
        global_event_emitter.$off("hackathon_sidebar_reload_required");
    },
};
</script>

<style>
.btn-circle {
    /* width: 35px;
  height: 35px;
  padding: 5px;
  border-radius: 30px;
  font-size: 14px;
  text-align: center;
  line-height: 1.42857; */
    width: 50px;
    height: 50px;
    padding: 0px;
    padding-left: 1px;
    border-radius: 30px;
    font-size: 30px;
    text-align: center;
    line-height: 1.42857;
}
.btn-details {
    /* width: 70%;
  height: 50%; */
    /* width: 40px;
  height: 40px;
  padding: 5px;
  padding-left: 6px;;
  text-align: center;
  line-height: 1.42857;
  font-size: 20px; */
    /* font-size: 150%; */
    /* display: block;
  margin: auto; */
}
</style>
